<template>
  <v-container class="white lighten-5 min-h-100 px-0" fluid>
    <v-card-title>
      <v-spacer></v-spacer>
      Add Company Type
      <v-spacer></v-spacer>
    </v-card-title>
    <v-col cols="12" sm="8" xs="12" class="mx-auto">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
          required
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validateForm"
        >
          Submit
        </v-btn>

        <!-- <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn> -->
      </v-form>
    </v-col>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'AddCompanyType',
  data() {
    return {
      valid: true,
      name: '',
      nameRules: [(v) => !!v || 'Name is required'],
    };
  },
  watch: {
    getRole(r) {
      if (r?.id == null || r?.id != this.$store.superadmin) {
        this.$router.replace('/');
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      const list = this.companiesType.filter(
        (e) => e.id == this.$route.params.id
      );
      if (list.length > 0) {
        this.name = list[0].name;
      }
    }
  },
  computed: {
    ...mapGetters(['getRole']),
    ...mapState({
      companiesType: (state) => state.companiesType,
      superadmin: (state) => state.superadmin,
    }),
  },
  mounted() {
    if (this.getRole?.id != this.superadmin) {
      this.$router.push('/');
      return;
    }
  },
  methods: {
    ...mapActions(['addCompanyType']),
    async validateForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        await this.addCompanyType({
          companyType: {
            name: this.name,
          },
          id: this.$route.params.id,
        });
        this.$router.go(-1);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
